<template>
  <div class="h-full w-full">
    <!-- <div class="ta-right">
		<el-button type="primary"  @click="addModal=true" >新增</el-button>
	</div> -->
    <el-table
      v-loading="tableLoading"
      :data="tableData"
      border
      class="w-full"
      :height="tableHeight"
    >
      <!-- <el-table-column label="ID" width="80" prop="id"></el-table-column> -->
      <el-table-column
        :label="item"
        :prop="name"
        v-for="(item, name, index) in dicts"
        :key="index"
      ></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button @click="gotoEdit(scope.row)" type="warning" size="mini"
            >编辑</el-button
          >
          <el-button @click="gotoDelete(scope.row)" type="danger" size="mini"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 8px"
      v-if="total !== null"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page="currentPage"
      :page-size="size"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import object from "@/api/objects";
import { Message } from "element-ui";
export default {
  name: "list",
  props: {
    obuuid: {
      type: String,
      default: "",
    },
    view_uuid: {
      type: String,
      default: "",
    },
    needPage: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    tableHeight() {
      if (this.total === null || !this.total) {
        return "100%";
      } else {
        return "calc(100% - 44px)";
      }
    },
    idUuid(){
      let res = ''
      let keys = Object.keys(this.dicts)
      keys.forEach((item) => {
        if(this.dicts[item] == 'id' || this.dicts[item] == 'ID'){
          res = item
        } 
      })
      return res
    }
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      tableLoading: false,
      total: null,
      size: 14,
      timer: null,
      dicts: null,
    };
  },
  watch: {
    search() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.currentPage = 1;
        this.getDatas();
        clearTimeout(this.timer);
      }, 500);
    },
  },
  methods: {
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getDatas();
    },
    gotoDelete(data){
      if(!data[this.idUuid]){
        this.$message.error('错误的数据id');
        return 
      }
      this.$confirm('确认删除该数据吗？','提示').then(res=>{

        if(res == 'confirm'){
          object.mapi.common({
            __method_name__:'deleteData',
            object_uuid:this.obuuid,
            data_id:data[this.idUuid],
          }).then(()=>{
            this.getDatas()
          })
        }
      }).then(()=>{})
    },
    handleSizeChange(size) {
      this.size = size;
      this.getDatas();
    },
    gotoEdit(data) {
      if(!this.$route){
        this.$message.error("暂不支持编辑！")
        return
      }
      let dicts = Object.keys(this.dicts);
      for (let i = 0; i < dicts.length; i++) {
        let item = dicts[i];
        if (this.dicts[item] == "id" || this.dicts[item] == "ID") {
          if (data[item] === undefined || !data[item]) {
            Message.error("该数据ID有误");
            return;
          }
          // this.$router.push({path:'/formList/'+this.obuuid,query:{id:data[item]}})
          this.$router.replace({
            path: "/dwh/formList",
            query: {
              obuuid: this.$route.query.obuuid,
              obname: this.$route.query.obname,
              type: "form",
              id: data[item],
            },
          });
          return;
        }
      }
      Message.error("该数据没有ID");
      return;
    },
    getDatas() {
      this.tableLoading = true;
      object.mapi
        .getList(this.obuuid, this.currentPage, this.size, this.view_uuid)
        .then((res) => {
          // this.tableLoading = false
          let dicts = {};

          if (res.data.data && res.data.data.length) {
            this.tableData = res.data.data;
          } else {
            this.tableData = res.data.data.data;
          }
          this.tableData.forEach((item, index) => {
            let keys = Object.keys(item);
            keys.forEach((key) => {
              if (index == 0) {
                // 过滤metadata	没在接口数据中出现的字段不予显示
                dicts[key] = res.data.metadata[key];
              }
              if (item[key] instanceof Array) {
                item[key] = "共" + item[key].length + "条";
              } else if (item[key] && item[key].length > 50) {
                let tmp = item[key].slice(0, 48) + "...";
                item[key] = tmp;
              }
            });
          });
          this.dicts = dicts;

          this.tableLoading = false;
          this.tableLoading = false;
          if (res.data.data.total) {
            this.total = res.data.data.total;
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
  },

  mounted() {
    this.getDatas();
  },
};
</script>