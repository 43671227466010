/*
 * @LastEditTime: 2022-08-04 15:09:35
 * @Description: 展示已知数据的表单
 * @FilePath: /dwh_front/src/global/actions/ShowDataForm.js
 * @Date: 2022-05-13 17:19:30
 * @Author: lisushuang
 * @LastEditors: ttheitao
 */
/* eslint-disable */

import baseAction from "./baseAction"
import ViewData from "@/views/objects/components/viewData"
import objects from "../../api/objects"
import Vue from 'vue';
import { Button, Message, MessageBox } from "element-ui";

export default class ShowViewData extends baseAction {

  view_uuid = null
  object_uuid = null
  name = null

  constructor() {
    super()
  }

  /**
   * @description: 核心处理方法
   */
  handler() {

    console.log('ShowDataForm',this.props, this.props.uuid)
    if(this.props.form_uuid){
      // 已经传入了form_uuid
    }else{
      // 没传的时候要自己去查询，选一个
    }


  }

}